import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_11/slide9';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    leftColumn: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "slide-4-11-8"}}) {
        body
      },
    zeromski: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-11-9-zeromski"}}) {
      body
    },
    wars: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-11-9-wars"}}) {
      body
    },
    mackiewicz: mdx(frontmatter: {language: {eq: "FR"}, title: {eq: "4-11-9-mackiewicz"}}) {
      body
    }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
